.doormat {
  padding: 1rem;
  @media (min-width: $screen-sm-min) { padding: 2rem 0; }
  @media (min-width: $screen-md-min) { padding: 3rem 0; }
  min-height: 10rem;
  background: $highland url('../images/bkgd-doormat.png');
  color: lighten($fire, 60%);
  a {
    color: $warning-subtle;
    &:hover {
      color: $link-color;
    }
  }
  h5 {
    color: inherit;
  }
  ul { @extend %no-bullets; }
  .contact-info {
    @include make-sm-column-pull(9);
    @include make-sm-column(3);
    margin-top: 1.5rem;
    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      padding-left: 2rem;
    }
  }
  .doormat--menu {
    @include make-sm-column-push(3);
    @include make-sm-column(9);
    @media (min-width: $screen-sm-min) { padding-right: 2rem; }
    h5 { border-bottom: 0.0625rem solid $warning-subtle; }
  }
  .doormat--menu--cities {
    @include make-xs-column(6);
    @include make-sm-column(7);
  }
  .doormat--menu--other {
    @include make-xs-column(6);
    @include make-sm-column(5);
  }
  .social-links { margin-top: 1.5rem; }
}
