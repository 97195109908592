.city-hero {
  min-height: 30rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.city-hero--overlay {
  position: absolute;
  top: 21.5rem;
  left: 0;
  right: 0;
  // bottom: 0;
  @media (min-width: $screen-sm-min) {
    position: relative;
    top: 20rem;
    width: 50%;
  }
  @media (min-width: $screen-md-min) {
    width: 40%;
  }
  padding: 1.5rem 3rem;
  background-color: rgba($neutral-40, 0.8);
  color: $neutral-80;
  @include brandon-grotesque;
  font-weight: 400;
  a:hover {
    text-decoration: none;
    color: $warning;
  }
  h1 {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
  }
  nav {
    ul { @extend %no-bullets; }
    li {
      display: inline-block;
      margin-right: 0.375rem;
    }
  }
  time { text-transform: uppercase; }
}

.city-hero--btn {
  @include abolition;
  display: block;
  width: 100%;
  padding: 0.25rem;
  font-size: 2.5rem;
  text-align: center;
}

.city-hero--btn-closed {
  background-color: $brand-primary-s20;
  color: $brand-primary-h30;
}

.city-hero--btn-coming-soon {
  background-color: $brand-secondary;
  color: $brand-secondary-s30;
}

.city-hero--btn-register {
  background-color: $brand-primary;
  color: $brand-primary-h60;
  &:hover {
    text-decoration: none;
    background-color: $brand-primary-s10;
    color: white;
  }
  form { display: inline; }
}

.city-hero--btn-sold-out {
  background-color: $link-color;
  color: $link-hover;
}

.city-hero--btn-tba {
  background-color: $success;
  color: $success-subtle;
}

.city-info { margin-bottom: 4rem; }

.city-tabs {
  @include make-row();
  a {
    @include abolition;
    float: left;
    display: inline-block;
    margin: 0;
    width: 33.33%;
    padding: 0.25rem 1rem;
    font-size: 1.5rem;
    span {
      display: inline-block;
      width: 85%;
    }
    i {
      display: none;
      @media (min-width: $screen-sm-min) { display: inline-block; }
      width: 12%;
      text-align: right;
    }
  }
  .crawl-info {
    background-color: $orange-roughy;
    color: tint($orange-roughy, 40%);
  }
  .captain-info {
    background-color: $brand-secondary;
    color: $brand-secondary-h20;
  }
  .charities {
    background-color: $success;
    color: $success-subtle;
  }
}

.letters {
  margin-top: 5rem;
  @media (min-width: $screen-sm-min) { margin-top: 8rem; }
  img {
    @include img-responsive();
    border: 0.25rem solid white;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0,0,0,0.3);
  }
}

.mailing-list-signup {
  width: 80%;
  min-height: 7.25rem;
  background-color: $success-subtle;
  div {
    padding: 0.5rem 0.75rem 1rem;
  }
  h4,
  p {
    float: left;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    color: darken($success, 10%);
  }
  h4 {
    width: 60%;
    font-size: 1.5rem;
    background-color: transparent;
    font-weight: 400;
  }
  p {
    width: 40%;
    line-height: 1;
  }
  form {
    clear: both;
    position: relative;
    top: 1rem;
    input {
      float: left;
      width: 80%;
      border: 0;
      padding: 0.5rem;
      background-color: lighten($success, 40%);
      color: darken($success, 20%);
      font-size: 1.25rem;
      &::placeholder { color: $success; }
    }
    input[type='submit'] {
      width: 20%;
      background-color: darken($success, 20%);
      @include abolition;
      color: lighten($success, 40%);
      text-align: center;
    }
  }
}

.testimonial {
  img,
  figcaption {
    display: inline-block;
    vertical-align: middle;
  }
  img {
    position: relative;
    z-index: 5;
    max-width: 6rem;
    height: auto;
    border: 0.3125rem solid $california;
    border-radius: 50%;
  }
  figcaption {
    width: 80%;
    margin-left: -3rem;
    padding: 0.5rem 1.5rem 0.5rem 4rem;
    background-color: $neutral-40;
    color: $neutral-80;
    h5 { color: inherit; }
    p {
      font-size: 0.875rem;
      font-style: italic;
    }
  }
}

// Lightbox JS stuff

/* Preload images */
body:after {
  content: url(../images/close.png) url(../images/loading.gif) url(../images/prev.png) url(../images/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;

  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
