.navbar {
  margin-bottom: 0;
}

.main-nav {
  @include brandon-grotesque;
  min-height: 5.75rem;
  font-weight: 400;
  text-transform: uppercase;
  background: $cardinal url('../images/bkgd-nav.png');
  a {
    color: $brand-primary-h60;
  }

  .nav > li {
    height: 5.75rem;
    transition: all 0.3s ease;

    &:hover {
      color: $california
    }
  }

  .nav > li > a {
    position: relative;
    top: 50%;
    margin-top: -1.875rem;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    color: lighten($california, 10%);
  }

  .navbar-toggle { color: white; }

  .new-crawl {
    background: $california;
    border-radius: 0.25rem;
    &:hover {
      background: lighten($california, 20%) !important;
      color: darken($california, 20%) !important;
    }
  }

  .nav-logo {
    width: 5rem;
    height: auto;
    transition: all 0.3s;
    @media (min-width: $screen-sm-min) { width: 10rem; }
  }

  .nav-logo-smaller {
    @media (min-width: $screen-sm-min) { width: 5rem; }
  }
}

.mega-menu {
  padding: 10px 0 !important;
  width: 540px;
  border-radius: 0;
  margin-top: 0;
  background-color: $brand-primary-s20;
}

.mega-menu li {
  display: inline-block;
  float: left;
  font-size: 0.875rem;
  padding: 0.25rem 0;
}

.mega-menu-column {
  margin-right: 1rem;
  width: 30%;
  li {
    width: 100%;
  }
}


.nav--letters {
  ul {
    @extend %no-bullets;
  }

  li {
    @include brandon-grotesque;
    display: inline-block;
    margin-right: 0.5rem;

    a {
      padding: 0.1875rem 0.5rem;
      background-color: $brand-secondary-h40;
      color: $brand-secondary-s20;
      border-radius: 0.1875rem;

      &:hover {
        background-color: $brand-secondary-h20;
        color: $brand-secondary-s10;
        text-decoration: none;
      }
    }
  }
}
