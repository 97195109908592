/* Structural Declarations */

html {
  font-size: 100%;
}

body {
  @include calluna;
  line-height: 1.5;
  background: url('../images/groovepaper.png');
  color: $copy-default;
}

.hide {
  display: none;
}

.module {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.module--nested {
  @extend %container;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

// .parallax-slider { margin-top: 12.75rem; }
