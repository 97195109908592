.about--why-we-crawl {
  @extend %container;
  article { @include make-row(); }
  figure {
    @include make-sm-column(4);
    @media (max-width: $screen-xs-max) { margin-top: 2rem; }
    img { @extend %img-responsive; }
  }
  .about--why-we-crawl--content {
    @include make-sm-column(8);
    h2 { margin-top: 0; }
  }
}
.about--why-should-you-crawl {
  background-color: $neutral-80;
  article { @include make-row(); }
  .about--why-should-you-crawl--content { @include make-sm-column(12); }
}
