@mixin abolition {
  font-family: "abolition",sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin brandon-grotesque {
  font-family: "brandon-grotesque",sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin calluna($weight:400, $style:normal) {
  font-family: "calluna",serif;
  font-style: $style;
  font-weight: $weight;
}
