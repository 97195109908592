$highland: #7f905d;
$light-slate-gray: #728d97;
$east-bay: #4a536a;
$fire: #b34301;
$california: #eb9038;
$cardinal: #be1e2d;
$bright-red: #96232d;
$orange-roughy: #bf5822;

$brand-primary-h60: lighten($bright-red, 60%);
$brand-primary-h30: lighten($bright-red, 30%);
$brand-primary: $bright-red;
$brand-primary-s10: darken($bright-red, 10%);
$brand-primary-s20: darken($bright-red, 20%);
$brand-primary-s30: darken($bright-red, 30%);

$brand-secondary-h40: lighten($light-slate-gray, 40%);
$brand-secondary-h20: lighten($light-slate-gray, 20%);
$brand-secondary: $light-slate-gray;
$brand-secondary-s10: darken($light-slate-gray, 10%);
$brand-secondary-s20: darken($light-slate-gray, 20%);
$brand-secondary-s30: darken($light-slate-gray, 30%);

$link-color: $california;
$link-hover: $fire;

$neutral-100: lighten($east-bay, 60%);
$neutral-80: lighten($east-bay, 50%);
$neutral-60: lighten($east-bay, 25%);
$neutral-40: $east-bay;
$neutral-20: darken($east-bay, 10%);
$neutral-0: darken($east-bay, 30%);

$copy-default: $east-bay;
$copy-success: darken($highland, 20%);
$copy-error: lighten($cardinal, 50%);
$copy-warning: darken($california, 30%);

$success: $highland;
$success-subtle: lighten($highland, 30%);
$error: $cardinal;
$error-subtle: lighten($cardinal, 30%);
$warning: lighten($california, 20%);
$warning-subtle: lighten($california, 32%);
