%container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

%img-responsive { @include img-responsive; }

%container-fluid {
  @include container-fixed;
}

%row {
  @include make-row;
}

%full-width {
  @include make-sm-column(12);
}

%two-up {
  @include make-sm-column(6);
}

%three-up {
  @include make-sm-column(4);
}

%four-up {
  @include make-sm-column(3);
}

%six-up {
  @include make-xs-column(6);
  @include make-sm-column(3);
  @include make-md-column(2);
}

%no-bullets {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
