.utility {
  margin-top: 5rem;
  @media (min-width: $screen-sm-min) { margin-top: 8rem; }
  .alert,
  .list-group-item,
  .nav-tabs {
    @include brandon-grotesque();
    text-transform: uppercase;
  }
  .panel-title { background: transparent; }
  .table {
    background-color: white;
    thead {
      @include brandon-grotesque();
      text-transform: uppercase;
    }
  }
}
