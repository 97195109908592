a {
  color: $link-color;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover { color: $link-hover; }
}

address { font-size: 0.875rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  @include brandon-grotesque;
  text-transform: uppercase;
  line-height: 1.2;
}

h1 {
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  background-color: $brand-primary;
  font-size: 2rem;
  color: $brand-primary-h60;
}

h2 {
  font-size: 1.5rem;
  color: $brand-secondary;
}

h3 { font-size: 1.25rem; }

h4 {
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  background-color: $brand-secondary;
  font-size: 1rem;
  color: $brand-secondary-h40;
}

h5 {
  font-size: 1rem;
  color: $neutral-60;
}

h6 {
  font-size: 0.75rem;
  color: $neutral-60;
}

hr {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  top: 1rem;
  border: 0;
  height: 0.0625rem;
  color: $neutral-80;
  background-color: $neutral-80;
}

p,
li,
address {
  font-size: 1rem;
}

.inner-heading {
  margin-top: 0;
  text-align: center;
}
