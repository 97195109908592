.home--cities,
.home--email-capture,
.home--social-why {
  @extend %container;
  article { @include make-row(); }
}

.home--cities,
.cities {
  margin-top: 3rem;
    h2 {
      color: $brand-primary;
      font-size: 2rem;
      text-align: center;
    }
    &--jump-point {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-md-column(3);
      @include make-lg-column(2);
      text-align: center;
      margin-bottom: 1rem;
      transition: all 0.3s;
      &:hover { transform: translateY(-0.5rem); }
      a:hover {
        text-decoration: none;
      }
      h4 {
        margin-bottom: 0;
        line-height: 1;
        background: transparent;
        color: $success;
        font-size: 0.875rem;
      }
      img {
        @include img-responsive();
        border: 0.25rem solid white;
        box-shadow: 0 0.25rem 0.25rem 0 rgba(0,0,0,0.3);
      }
      p {
        @include brandon-grotesque();
        margin: 0;
        line-height: 1;
        color: $brand-secondary-s10;
        font-size: 0.75rem;
      }
    }
}

.home--email-capture {
  background-color: $success-subtle;
  figure {
    @include make-sm-column(8);
    @include make-sm-column-offset(2);
  }
}

.home--hero-welcome {
  padding: 2rem 4rem;
  background-color: $california;
  p {
    @include brandon-grotesque;
    font-weight: 300;
    color: white;
    font-size: 1.25rem;
  }
}

.home--parallax {
  height: 28rem;
}

.home--social-why {
  h2 {
    color: $brand-primary;
    font-size: 2rem;
    text-align: center;
  }
  .crawl-disclaimer {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border: 1px solid $brand-primary-h30;
    background-color: $brand-primary;
    text-align: center;
    h2,
    p {
      color: $brand-primary-h60;
    }
    h2 {
      margin-top: 0;
      font-size: 2.25rem;
    }
    p { font-size: 1.25rem; }
  }
  .crt-feed-more {
    @include abolition;
    text-transform: uppercase;
    font-size: 1.5rem;
    a {
      padding: 0.5rem !important;
      background-color: $neutral-40 !important;
      color: $neutral-80 !important;
      transition: all 0.3s;
      &:hover {
        background: darken($neutral-40, 5%) !important;
        color: white !important;
        transform: scale(1.05);
      }
    }
  }
  .crt-post-header { margin-top: 1rem; }
  .crt-social-icon { margin-bottom: 0; }
  .crt-post-content-text {
    font-size: 0.75rem;
    text-align: left;
  }
  .crt-post-footer { font-size: 0.75rem; }
  .crt-post-fullname,
  .crt-post-username,
  .crt-date {
    @include brandon-grotesque();
    text-transform: uppercase;
  }
  .crt-date,
  .crt-post-fullname { display: none !important; }
  @media (max-width: $screen-xs-max) {
    .crt-feed-more { display: none; }
  }
}
