.form-group {
  label {
    @include brandon-grotesque;
    color: $brand-secondary;
    text-transform: uppercase;
  }

  .form-control {
    padding: 1.25rem 0.75rem;
    font-size: 1rem;

    &:focus {
      border-color: $neutral-60;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($neutral-60, 0.6);
    }
  }
}

.signup-error,
.signup-form {
  margin-top: 5rem;
  @media (min-width: $screen-sm-min) { margin-top: 8rem; }
}

.signup-form {
  .additional-info {
    @media (min-width: $screen-sm-min) {
      .form-group { min-height: 15.625rem; }
    }
  }
  .color-swatches {
    .color-swatch {
      float: left;
      margin: 0.25rem;
      width: 3rem;
      height: 3rem;
      @media (min-width: $screen-sm-min) {
        width: 2rem;
        height: 2rem;
      }
      border: 1px solid $brand-secondary;
      border-radius: 0.25rem;
      &:hover { cursor: pointer; }
    }
  }

  .shirt-display {
    text-align: center;

    .shirt-icon {
      margin-bottom: 1rem;
    }
  }
  /*-- Gildan Colors --*/
  #yellowhaze {
    background-color: #ffe29b;
  }
  /* Yellow Haze */
  #serenegreen {
    background-color: #bbc6af;
  }
  /* Serene Green */
  #stoneblue {
    background-color: #5f8da4;
  }
  /* Stone Blue */
  #heliconia {
    background-color: #ca3661;
  }
  /* Heliconia */
  #cardinalred {
    background-color: #9f212d;
  }
  /* Cardinal Red */
  #lime {
    background-color: #b7eb00;
  }
  /* Lime */
  #kellygreen {
    background-color: #008254;
  }
  /* Kelly Green */
  #vegasgold {
    background-color: #f6ebb3;
  }
  /* Vegas Gold */
  #lightpink {
    background-color: #f8c5db;
  }
  /* Light Pink */
  #icegrey {
    background-color: #dce2ec;
  }
  /* Ice Grey */
  #gold {
    background-color: #fbc30d;
  }
  /* Gold */
  #texasorange {
    background-color: #f38f1d;
  }
  /* Texas Orange */
  #sapphire {
    background-color: #0087be;
  }
  /* Sapphire */
  #jadedome {
    background-color: #008180;
  }
  /* Jade Dome */
  #navy {
    background-color: #002e4f;
  }
  /* Navy */
  #forestgreen {
    background-color: #00483a;
  }
  /* Forest Green */
  #black {
    background-color: #000000;
  }
  /* Black */
  #olive {
    background-color: #5c5743;
  }
  /* Olive */
  #red {
    background-color: #d71c2d;
  }
  /* Red */
  #charcoal {
    background-color: #606364;
  }
  /* Charcoal */
  #royal {
    background-color: #0668b2;
  }
  /* Royal */
  #iris {
    background-color: #4680c1;
  }
  /* Iris */
  #prairiedust {
    background-color: #a2988a;
  }
  /* Prairie Dust */
  #lightblue {
    background-color: #9ac2e7;
  }
  /* Light Blue */
  #azalea {
    background-color: #d977a9;
  }
  /* Azalea */
  #bluedusk {
    background-color: #3c4652;
  }
  /* Blue Dusk */
  #metroblue {
    background-color: #113f71;
  }
  /* Metro Blue */
  #indigoblue {
    background-color: #00629f;
  }
  /* Indigo Blue */
  #purple {
    background-color: #3a2d76;
  }
  /* Purple */
  #maroon {
    background-color: #681a29;
  }
  /* Maroon */
  #chestnut {
    background-color: #6e5948;
  }
  /* Chestnut */
  #violet {
    background-color: #7484c1;
  }
  /* Violet */
  #sportgrey {
    background-color: #bbb9b4;
  }
  /* Sport Grey */
  #daisy {
    background-color: #ffda4f;
  }
  /* Daisy */
  #darkchocolate {
    background-color: #402b28;
  }
  /* Dark Chocolate */
  #militarygreen {
    background-color: #5e624f;
  }
  /* Military Green */
  #darkheather {
    background-color: #383a42;
  }
  /* Dark Heather */
  #carolinablue {
    background-color: #659ad1;
  }
  /* Carolina Blue */
  #irishgreen {
    background-color: #00b159;
  }
  /* Irish Green */
  #heathercardinal {
    background-color: #b84e52;
  }
  /* Heather Cardinal */
  #heathernavy {
    background-color: #555965;
  }
  /* Heather Navy */
  #heatherindigo {
    background-color: #7990b0;
  }
  /* Heather Indigo */
  #kiwi {
    background-color: #bcd752;
  }
  /* Kiwi */
  #sky {
    background-color: #7dd1e1;
  }
  /* Sky */
  #honey {
    background-color: #ffd100;
  }
  /* Honey */
  #orchid {
    background-color: #bab8ff;
  }
  /* Orchid */
  #cherryred {
    background-color: #ac2b37;
  }
  /* Cherry Red */
  #antiquecherryred {
    background-color: #971b2f;
  }
  /* Antique Cherry Red */
  #antiqueirishgreen {
    background-color: #00843d;
  }
  /* Antique Irish Green */
  #antiqueorange {
    background-color: #b33d26;
  }
  /* Antique Orange */
  #antiqueroyal {
    background-color: #003087;
  }
  /* Antique Royal */
  #aubergine {
    background-color: #5c2d54;
  }
  /* Aubergine */
  #berry {
    background-color: #7f2952;
  }
  /* Berry */
  #blackberry {
    background-color: #4a3041;
  }
  /* Blackberry */
  #coralsilk {
    background-color: #df6b7d;
  }
  /* Coral Silk */
  #electricgreen {
    background-color: #43b02a;
  }
  /* Electric Green */
  #galapagosblue {
    background-color: #005d6f;
  }
  /* Galapagos Blue */
  #oldgold {
    background-color: #ca9f75;
  }
  /* Old Gold */
  #pistachio {
    background-color: #bdc293;
  }
  /* Pistachio */
  #russett {
    background-color: #512f2e;
  }
  /* Russett */
  #rustybronze {
    background-color: #8e4344;
  }
  /*Rusty Bronze */
  #safetypink {
    background-color: #e16f8f;
  }
  /* Safety Pink */
  #sunset {
    background-color: #ff7f41;
  }
  /* Sunset */
  #tropicalblue {
    background-color: #00889b;
  }
  /*Tropical Blue */
  #turfgreen {
    background-color: #1c704d;
  }
  /*Turf Green */
  #brownsavana {
    background-color: #776a60;
  }
  /* Brown Savana */
  #tweed {
    background-color: #63666a;
  }
  /* Tweed */
  #random {
    background: linear-gradient(to bottom, rgb(255,50,50) 0%,rgb(255,134,48) 17%,rgb(255,255,48) 36%,rgb(62,255,48) 54%,rgb(40,172,255) 71%,rgb(97,40,255) 84%,rgb(152,0,255) 100%);
  }
}
