.cities {
  margin-top: 4rem;
  @media (min-width: $screen-sm-min) { margin-top: 8rem; }
  @media (min-width: $screen-md-min) { margin-top: 6rem; }
}

.city--bars-and-sponsors {
  @extend %container;
  article { @include make-row(); }
  h3 {
    padding: 0.5rem;
    margin-bottom: 1.875rem;
    color: $brand-primary-h60;
    background-color: $brand-primary;
    text-align: center;
  }
  .city--bars-and-sponsors--bars,
  .city--bars-and-sponsors--charities,
  .city--bars-and-sponsors--sponsors {
    @include make-sm-column(4);
  }
  .logo-grid {
    @include make-row();
    &--logo {
      @include make-sm-column(6);
      img {
        @include img-responsive;
        margin-bottom: 1.875rem;
      }
    }
  }
}
.city--key-info {
  @extend %container;
  article { @include make-row(); }
  .city--key-info--route-map,
  .city--key-info--key-dates {
    @include make-sm-column(6);
    ul { @extend %no-bullets; }
    li {
      margin-bottom: 1rem;
      strong {
        @include brandon-grotesque;
        color: $highland;
      }
    }
  }
}
