.ui-btn {
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
  @include abolition;
  display: block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  font-size: 1.25rem;
  cursor: pointer;
  border: 0.0625rem solid transparent;
  white-space: nowrap;
  transition: all 0.3s ease;
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
    transform: scale(1.05);
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 0.1875rem 0.3125rem rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }
}

.ui-btn-lg {
  padding: 0.5rem 1.25rem;
  font-size: 2rem;
}

.ui-btn-text {
  @include brandon-grotesque;
  position: relative;
  text-transform: uppercase;
  &::after {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background: url('../images/icon-arrowhead.svg') no-repeat;
    top: 5px;
    right: -24px;
    opacity: 0.3;
    transition: all 0.3s ease;
    color: inherit;
  }
  &:hover {
    text-decoration: none;
  }
  &:hover:after,
  &:active:after {
    right: -28px;
    opacity: 1;
  }
}

.ui-btn-aggressive {
  background: $error;
  color: lighten($error-subtle, 17%);
  &:hover {
    background: darken($error, 5%);
    color: white;
  }
}

.ui-btn-default {
  background-color: $neutral-40;
  color: $neutral-80;
  &:hover {
    background: darken($neutral-40, 5%);
    color: white;
  }
}

.ui-btn-energetic {
  background: $california;
  color: lighten($california, 30%);
  &:hover {
    background: darken($california, 5%);
    color: white;
  }
}

.ui-btn-subtle {
  background-color: $success;
  color: lighten($success-subtle, 10%);
  &:hover {
    background: darken($success, 5%);
    color: white;
  }
}

.ui-btn-ghost-default {
  border: 0.125rem solid $neutral-40;
  color: $neutral-40;
  &:hover {
    border-color: $neutral-80;
    color: $neutral-40;
  }
}

.ui-btn-ghost-aggressive {
  border: 0.125rem solid $error;
  color: $error;
  &:hover {
    border-color: lighten($error-subtle, 10%);
    color: $error;
  }
}

.ui-btn-ghost-energetic {
  border: 0.125rem solid $california;
  color: $california;
  &:hover {
    border-color: $warning-subtle;
    color: $california;
  }
}

.ui-btn-ghost-subtle {
  border: 0.125rem solid $success;
  color: $success;
  &:hover {
    border-color: $success-subtle;
    color: $success;
  }
}
